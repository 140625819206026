interface String {
	toFirstUpper(): string;

	toCamelCase(): string;

	toFirstWordUpper(): string;

	// similarity(_s2: string): number;
}
/*
function editDistance (s1: string, s2: string): number {
	s1 = s1.toLowerCase();
	s2 = s2.toLowerCase();

	const costs = [];
	for (let i = 0; i <= s1.length; i++) {
		let lastValue = i;
		for (let j = 0; j <= s2.length; j++) {
			if (i == 0)
				costs[j] = j;
			else {
				if (j > 0) {
					let newValue = costs[j - 1];
					if (s1.charAt(i - 1) != s2.charAt(j - 1)) {
						newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1;
					}
					costs[j - 1] = lastValue;
					lastValue = newValue;
				}
			}
		}
		if (i > 0) {
			costs[s2.length] = lastValue;
		}
	}
	return costs[s2.length];
};

String.prototype.similarity = function (_s2: string): number {
	let longer = this.toString();
	let shorter = _s2;
	if (this.length < _s2.length) {
		longer = _s2;
		shorter = this.toString();
	}
	const longerLength = longer.length;
	if (longerLength == 0) {
		return 1.0;
	}
	return (longerLength - editDistance(longer, shorter)) / parseFloat(longerLength.toString());
};
*/

String.prototype.toFirstUpper = function (): string {
	return this.replace(/^[a-z]/g, (ltr, idx) => idx === 0 ? ltr.toUpperCase() : ltr.toLowerCase());
};

String.prototype.toCamelCase = function (): string {
	return this.replace(/(?:^\w|[A-Z]|-|\b\w)/g,
		(ltr, idx) => idx === 0
			? ltr.toLowerCase()
			: ltr.toUpperCase()
	).replace(/\s+|-/g, '');
};

String.prototype.toFirstWordUpper = function (): string {
	return this.replace(/(?:^\w|[A-Z]|-|\b\w)/g,
		(ltr, idx) => idx === 0
			? ltr.toLowerCase()
			: ltr.toUpperCase()
	);
};
