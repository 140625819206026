import {NgModule} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';

import {Bonuomo9Maggio2024RoutingModule} from './bonuomo9-maggio2024-routing.module';
import {Bonuomo9Maggio2024Component} from './bonuomo9-maggio2024.component';
import {PrimengModule} from '../../modules/primeng.module';
import {InputSwitchModule} from 'primeng/inputswitch';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CoreModule} from '../../core/core.module';
import {FormComponent} from './components/form/form-component.component';
import {SitoComponent} from "./components/sito/sito.component";

// console.log('bio module');

@NgModule({
    declarations: [
        FormComponent,
        Bonuomo9Maggio2024Component,
        SitoComponent
    ],
    imports: [
        CommonModule,
        Bonuomo9Maggio2024RoutingModule,
        PrimengModule,
        InputSwitchModule,
        ReactiveFormsModule,
        FormsModule,
        CoreModule,
        NgOptimizedImage
    ],
    exports: [FormComponent]
})
export class Bonuomo9Maggio2024Module {
}
