import {AfterViewInit, Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Subscription} from "rxjs";
import {ActivatedRoute} from "@angular/router";
import {MessageService} from "primeng/api";
import {ApiService} from "../../services/api.service";

// Funzione esterna per il cambio del loadingText
declare var disableLoading: Function;

@Component({
    selector: 'app-tim-demo',
    templateUrl: './tim-demo.component.html',
    styleUrls: ['./tim-demo.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class TimDemoComponent implements OnInit, AfterViewInit {

    checkToken = false;
    tokenValid = false;
    alreadyVerified = false;
    event_id: string = '';
    token_reg: string = '';
    userData: any = {};
    loading = true;
    saving = false;
    unsubscribeCheck = false;
    unsubscribing = false;
    guestLink = false;
    sub: Subscription | undefined;

    constructor(private route: ActivatedRoute, private messageService: MessageService, private api: ApiService) {
    }

    ngOnInit(): void {
        this.sub = this.route.data.subscribe(v => {
            if (v.hasOwnProperty('unsubscribing')) {
                this.unsubscribing = v.unsubscribing;
            }

            if (v.hasOwnProperty('guestLink')) {
                this.guestLink = v.guestLink;
            }

            if (this.guestLink || this.unsubscribing) {
                this.verifyToken();
            } else {
                this.loading = false;
                this.checkToken = false;
            }
            // console.log(v);
        });

        setTimeout(() => {
            this.loading = false;
        }, 4000);
    }

    ngAfterViewInit() {
        // Richiamo la funzione per disattivare il loading
        setTimeout(() => {
            disableLoading();
        }, 2000);
    }

    verifyToken() {
        this.route.paramMap.subscribe(params => {
            let a2b;
            try {
                if (params.has('token_reg')) {
                    a2b = window.atob(params.get('token_reg') ?? '');
                }
            } catch (e) {
                console.warn(e);
                a2b = null;
            }

            const event_id = params.has('event_id') ? params.get('event_id') ?? '' : null;
            const token_reg = a2b;
            if (token_reg !== null && event_id !== null && typeof token_reg === 'string') {
                this.checkToken = false;
                this.confirmToken(token_reg, event_id);
            } else {
                setTimeout(() => {
                    this.loading = false;
                    this.checkToken = false;
                    this.messageService.add({
                        severity: 'error',
                        summary: 'Errore nei dati',
                        detail: 'Errore durante la lettura dei dati (001)',
                        life: 3000,
                        closable: false
                    });
                }, 500);
            }
        });
    }

    async confirmToken(token: string, event_id: string) {
        this.loading = true;

        await this.api.confirmToken({data: {token_reg: token, event_id: event_id}}).then(res => {
            // console.log(res.userData);
            if (res.status === 200 && res.code === 'auth/ok') {
                this.tokenValid = true;
                this.userData = res.userData;
                this.event_id = event_id;
                this.token_reg = token;
                this.unsubscribeCheck = this.userData.custom_fields.unsubscribe;
            } else if (res.status === 200 && res.code === 'auth/already_completed') {
                this.tokenValid = true;
                this.alreadyVerified = true;
                this.userData = res.userData;
                this.event_id = event_id;
                this.token_reg = token;

                this.unsubscribeCheck = this.userData.custom_fields.unsubscribe;

                // this.messageService.add({
                // 	severity: 'warn',
                // 	summary: this.langSrv.getLangDb().alert.formAlreadySubmitted,
                // 	detail: this.langSrv.getLangDb().alert.editingFormBeCareful,
                // 	life: 3000,
                // 	closable: false
                // });
            } else {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Errore nei dati',
                    detail: 'Errore durante la lettura dei dati (002)',
                    life: 3000,
                    closable: false
                });
                this.tokenValid = false;
            }
        }).catch(err => {
            // /console.error('error', err);
            this.messageService.add({
                severity: 'error',
                summary: 'Errore nei dati',
                detail: 'Errore durante la lettura dei dati (003)',
                life: 3000,
                closable: false
            });
        }).finally(() => {
            this.loading = false;
        });
    }

    formSubmitted($data: { partecipa: 0 | 1, formCompletato: boolean }) {
        // console.log($data);
        this.alreadyVerified = $data.formCompletato;
    }

    unsubscribeSubmit() {
        this.saving = true;
        this.messageService.clear();

        const body: any = {
            data: {
                event_id: this.event_id, uid: this.userData.uid, token_reg: this.token_reg, unsubscribe: this.unsubscribeCheck
            }
        };

        this.api.updateUnsubscribe(body).then(res => {
            this.messageService.clear();
            if (res.status === 200 && res.code === 'auth/ok') {
                this.messageService.add({severity: 'success', summary: 'Salvataggio', detail: 'Preferenza salvata', life: 3000, closable: false});
            } else {
                this.messageService.add({severity: 'error', summary: 'Errore', detail: 'Errore durante il salvataggio della preferenza', life: 3000, closable: false});
            }
        }).catch(err => {
            console.error('Errore durante il salvataggio', err);
            this.messageService.add({severity: 'error', summary: 'Errore', detail: 'Errore durante il salvataggio della preferenza', life: 3000, closable: false});
        }).finally(() => {
            this.saving = false;
        });
    }
}
