import {NgModule} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';

import {TimDemoRoutingModule} from './tim-demo-routing.module';
import {PrimengModule} from "../../modules/primeng.module";
import {InputSwitchModule} from "primeng/inputswitch";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CoreModule} from "../../core/core.module";
import {TimDemoComponent} from "./tim-demo.component";
import {FormTimDemoComponent} from "./form/form-component.component";


@NgModule({
    declarations: [
        TimDemoComponent,
        FormTimDemoComponent
    ],
    imports: [
        CommonModule,
        TimDemoRoutingModule,
        CommonModule,
        PrimengModule,
        InputSwitchModule,
        ReactiveFormsModule,
        CoreModule,
        FormsModule,
        NgOptimizedImage
    ]
})
export class TimDemoModule {
}
