import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {Bonuomo9Maggio2024Component} from './bonuomo9-maggio2024.component';
import {DefaultErrorComponent} from '../../core/default-error/default-error.component';

const hostName = window.location.host;

/**
 * Verifica se il dominio specificato è presente nel nome host corrente.
 * Considera anche la porta se presente nel nome host.
 *
 * @param {string} domain - Il dominio da verificare.
 * @returns {boolean} Ritorna true se il dominio corrisponde al nome host corrente, altrimenti false.
 */
const isOnDomain = (domain: string) => {
    return RegExp('(' + domain + ')|(' + domain + '):(\d+)?$').test(hostName);
};

const routes: Routes = [
    // {path: 'unsubscribe/:event_id/:token_reg', pathMatch: 'full', component: UnsubscribeComponent, data: {unsubscribing: true}},
    // Hard-codifico gli event_id
    // {path: '', pathMatch: 'full', component: Bonuomo9Maggio2024Component},
    {path: ':event_id', pathMatch: 'full', component: Bonuomo9Maggio2024Component},
    {path: '', redirectTo: isOnDomain('localhost') || isOnDomain('dev.form.studioditostobonuomo.it') ? '3QZr9nfD4D3NHnneRLIa' : 'IXSeVqz69VtXDdoKmV3H'},
    {path: '**', component: DefaultErrorComponent}
];

// console.log('bio route');

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class Bonuomo9Maggio2024RoutingModule {
}
