import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {RadioButtonModule} from 'primeng/radiobutton';
import {DropdownModule} from 'primeng/dropdown';
import {CheckboxModule} from 'primeng/checkbox';
import {InputTextModule} from 'primeng/inputtext';
import {ButtonModule} from 'primeng/button';
import {RippleModule} from 'primeng/ripple';
import {ToastModule} from 'primeng/toast';
import {ConfirmationService, MessageService} from 'primeng/api';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {CardModule} from 'primeng/card';
import {TooltipModule} from 'primeng/tooltip';
import {InputMaskModule} from 'primeng/inputmask';
import {CalendarModule} from 'primeng/calendar';
import {DialogModule} from 'primeng/dialog';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        RippleModule,
        ButtonModule,
        InputTextModule,
        CheckboxModule,
        DropdownModule,
        RadioButtonModule,
        InputTextareaModule,
        ConfirmDialogModule,
        ToastModule,
        ProgressSpinnerModule,
        TooltipModule,
        InputMaskModule,
        CardModule,
        CalendarModule,
        DialogModule
    ],
    exports: [
        RippleModule,
        ButtonModule,
        InputTextModule,
        CheckboxModule,
        DropdownModule,
        RadioButtonModule,
        InputTextareaModule,
        ConfirmDialogModule,
        ToastModule,
        ProgressSpinnerModule,
        TooltipModule,
        InputMaskModule,
        CardModule,
        CalendarModule,
        DialogModule
    ],
    providers: [
        MessageService,
        ConfirmationService
    ]
})
export class PrimengModule {
}
