import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ApiService} from "../../../../services/api.service";
import {MessageService} from "primeng/api";
import {AngularFirestore} from "@angular/fire/compat/firestore";

@Component({
    selector: 'app-form',
    templateUrl: './form-component.component.html',
    styleUrls: ['./form-component.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class FormComponent implements OnInit {
    // @Output() formSubmitted = new EventEmitter<{ partecipa: 0 | 1, formCompletato: boolean }>();

    @Input() event_id!: string;

    formCompleted = 0;
    userData!: { [key: string]: string | any };

    saving = false;

    registrationForm: FormGroup = new FormGroup({
        nome: new FormControl('', [Validators.required]),
        cognome: new FormControl('', [Validators.required]),
        email: new FormControl('', [Validators.required, Validators.email]),
        cellulare: new FormControl('', [Validators.required]),
        chk_privacy: new FormControl(false, [Validators.requiredTrue])
    });

    constructor(private api: ApiService, private messageService: MessageService, private af: AngularFirestore) {
    }

    ngOnInit(): void {
    }

    submit(): void {
        this.messageService.clear();

        if (!this.registrationForm.controls['chk_privacy'].value) {
            this.messageService.add({severity: 'error', summary: 'Attenzione', detail: 'Accettare la privacy policy prima di continuare.', life: 3000, closable: false});
            return;
        }

        const body: any = {
            data: {
                event_id: this.event_id,
                userData: {
                    partecipa: false,
                    formCompletato: false,
                    custom_fields: {
                        cellulare: '',
                        chkPrivacy: this.registrationForm.controls['chk_privacy'].value,
                    }
                }
            }
        };

        if (this.registrationForm.invalid) {
            this.messageService.add({severity: 'error', summary: 'Attenzione', detail: 'Completare il form in ogni sua parte.', life: 2000, closable: false});
            return;
        }

        // Raccolgo i dati del form
        body.data.userData.partecipa = true;
        body.data.userData.formCompletato = true;
        body.data.userData.nome = this.registrationForm.controls['nome'].value;
        body.data.userData.cognome = this.registrationForm.controls['cognome'].value;
        body.data.userData.email = this.registrationForm.controls['email'].value;
        body.data.userData.custom_fields.cellulare = this.registrationForm.controls['cellulare'].value;

        this.saving = true;

        this.api.userSignUpBonuomo(body).then(res => {
            if (res.status === 200 && res.code === 'auth/ok') {
                this.messageService.add({severity: 'success', summary: 'Salvataggio', detail: 'Salvataggio dei dati completato!', life: 2000, closable: false});
                // this.formSubmitted.emit({partecipa: body.data.userData.partecipa, formCompletato: body.data.userData.formCompletato});
                this.formCompleted = 1;
            } else if (res.status === 200 && res.code === 'auth/already_completed') {
                this.messageService.add({severity: 'info', summary: 'Attenzione', detail: 'Dati già salvati.', life: 3000, closable: false});
                this.formCompleted = 2;
                // this.formSubmitted.emit({partecipa: 1, formCompletato: true});
            } else {
                this.messageService.add({severity: 'error', summary: 'Errore', detail: 'Errore durante il salvataggio.', life: 3000, closable: false});
            }
        }).catch(err => {
            console.error('Errore durante il salvataggio', err);
            this.messageService.add({severity: 'error', summary: 'Errore', detail: 'Errore durante il salvataggio.', life: 3000, closable: false});
        }).finally(() => {
            this.saving = false;
        });
    }
}
