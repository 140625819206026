<div [formGroup]="registrationForm" class="grid dynamic-w">
    <div class="col-12 text-center flex flex-column mt-4">
        <span>Gentile <span class="capitalize lowercase color-tim-blue font-bold">{{ userData.nome }} {{ userData.cognome }}</span></span>
        <span>Le chiediamo cortesemente di confermare la Sua partecipazione.</span>
    </div>

    <div class="col-12 md:col-6 text-center md:text-right mt-4">
        <button #partecipoBt (click)="partecipo(partecipoBt)" [ngClass]="{'selected': chkPartecipo === 1}" class="button-blue" label="PARTECIPO" pButton type="button"></button>
    </div>

    <div class="col-12 md:col-6 text-center md:text-left mt-4">
        <button #nonPartecipoBt (click)="nonPartecipo(nonPartecipoBt)" [ngClass]="{'selected': chkPartecipo === 0}" class="button-blue" label="NON PARTECIPO" pButton type="button"></button>
    </div>

    <hr>

    <div *ngIf="chkPartecipo === 1" class="col-12">
        <div class="grid">
            <div class="col-12 text-left">
                Esigenze alimentari, intolleranze e allergie:
            </div>
            <div class="col-12 text-left flex justify-content-center align-items-center">
                <input class="w-full" formControlName="intolleranze" pInputText placeholder="Indicare qui eventuali dati" type="text">
            </div>

            <hr>

            <div class="col-12 text-left">
                A mio carico, vorrei invitare anche le seguenti persone
            </div>
            <div class="col-12 text-left flex justify-content-center align-items-center">
                <div class="grid">
                    <div class="col-12 text-left w-full flex color-tim-blue">Accompagnatore 1</div>
                    <div class="col-12 md:col-4 text-center flex"><input class="w-full" formControlName="accompagnatore1_nome" pInputText placeholder="Nome" type="text"></div>
                    <div class="col-12 md:col-4 text-center flex"><input class="w-full" formControlName="accompagnatore1_cognome" pInputText placeholder="Cognome" type="text"></div>
                    <div class="col-12 md:col-4 text-center flex"><input class="w-full" formControlName="accompagnatore1_email" pInputText placeholder="EMail" type="text"></div>
                    <div class="col-12 text-center flex"><input class="w-full" formControlName="accompagnatore1_intolleranze" pInputText placeholder="Esigenze alimentari, intolleranze e allergie" type="text"></div>
                    <div class="col-12 text-left w-full flex color-tim-blue mt-4">Accompagnatore 2</div>
                    <div class="col-12 md:col-4 text-center flex"><input class="w-full" formControlName="accompagnatore2_nome" pInputText placeholder="Nome" type="text"></div>
                    <div class="col-12 md:col-4 text-center flex"><input class="w-full" formControlName="accompagnatore2_cognome" pInputText placeholder="Cognome" type="text"></div>
                    <div class="col-12 md:col-4 text-center flex"><input class="w-full" formControlName="accompagnatore2_email" pInputText placeholder="EMail" type="text"></div>
                    <div class="col-12 text-center flex"><input class="w-full" formControlName="accompagnatore2_intolleranze" pInputText placeholder="Esigenze alimentari, intolleranze e allergie" type="text"></div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="chkPartecipo === 0" class="col-12">
        <div class="grid">
            <div class="col-12 text-center">
                <div class="field-checkbox flex align-items-center justify-content-start">
                    <p-checkbox (onChange)="toggleSuggerimento()" [binary]="true" class="mr-3" formControlName="nessunaPartecipazione" inputId="chkAltraPersona"></p-checkbox>
                    <label [for]="'chkAltraPersona'" class="">Non partecipo e nessun altro parteciperà al mio posto</label>
                </div>
            </div>
            <hr>
            <div class="col-12 text-left">
                Non potendo essere presente in prima persona, indico di seguito i dati di chi sarà presente al mio posto
            </div>
            <div class="col-12 text-left flex justify-content-center align-items-center">
                <div class="grid">
                    <div class="col-12 md:col-4 text-center flex"><input class="w-full" formControlName="suggerimento_nome" pInputText placeholder="Nome" type="text"></div>
                    <div class="col-12 md:col-4 text-center flex"><input class="w-full" formControlName="suggerimento_cognome" pInputText placeholder="Cognome" type="text"></div>
                    <div class="col-12 md:col-4 text-center flex"><input class="w-full" formControlName="suggerimento_email" pInputText placeholder="EMail" type="text"></div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="chkPartecipo > -1" class="col-12">
        <hr>
        <div class="grid justify-content-center align-items-center sm:m-auto">
            <div class="col-12 md:col-6">
                <div class="field-checkbox flex align-items-center justify-content-center">
                    <p-checkbox [binary]="true" class="mr-3 cursor-pointer" formControlName="chk_privacy" inputId="chk_privacy"></p-checkbox>
                    <label [for]="'chk_privacy'" class="cursor-pointer"><a href="https://www.google.com/search?q=privacy+policy" target="policy">Accetto la privacy policy</a></label>
                </div>
            </div>

            <div class="col-12 md:col-6">
                <div class="field-checkbox flex align-items-center justify-content-center">
                    <p-checkbox [binary]="true" class="mr-3 cursor-pointer" formControlName="chk_cookie" inputId="chk_cookie"></p-checkbox>
                    <label [for]="'chk_cookie'" class="cursor-pointer"><a href="https://www.google.com/search?q=cookie+policy" target="policy">Accetto la cookie policy</a></label>
                </div>
            </div>

            <div class="col-12 text-center my-4">
                <button (click)="submit()" [disabled]="saving" [loading]="saving" class="button-blue" label="INVIA" pButton type="button"></button>
            </div>
        </div>
    </div>
</div>
