import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ApiService} from "../../../services/api.service";
import {MessageService} from "primeng/api";
import {AngularFirestore} from "@angular/fire/compat/firestore";

@Component({
    selector: 'app-form-tim-demo',
    templateUrl: './form-component.component.html',
    styleUrls: ['./form-component.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class FormTimDemoComponent implements OnInit, OnDestroy {
    @Input() event_id!: string;
    @Input() token!: string;
    @Input() userData!: { [key: string]: string | any };
    @Output() formSubmitted = new EventEmitter<{ partecipa: 0 | 1, formCompletato: boolean }>();

    chkPartecipo: -1 | 0 | 1 = -1;
    saving = false;

    // @ts-ignore
    test: string;
    registrationForm: FormGroup = new FormGroup({
        intolleranze: new FormControl('', [Validators.minLength(2)]),
        accompagnatore1_nome: new FormControl('', [Validators.minLength(3)]),
        accompagnatore1_cognome: new FormControl('', [Validators.minLength(3)]),
        accompagnatore1_email: new FormControl('', [Validators.email]),
        accompagnatore1_intolleranze: new FormControl('', [Validators.minLength(2)]),
        accompagnatore2_nome: new FormControl('', [Validators.minLength(3)]),
        accompagnatore2_cognome: new FormControl('', [Validators.minLength(3)]),
        accompagnatore2_email: new FormControl('', [Validators.email]),
        accompagnatore2_intolleranze: new FormControl('', [Validators.minLength(2)]),
        suggerimento_nome: new FormControl('', [Validators.minLength(2)]),
        suggerimento_cognome: new FormControl('', [Validators.minLength(2)]),
        suggerimento_email: new FormControl('', [Validators.email]),
        nessunaPartecipazione: new FormControl(false),
        chk_privacy: new FormControl(false, [Validators.requiredTrue]),
        chk_cookie: new FormControl(false, [Validators.requiredTrue])
    });

    constructor(private api: ApiService, private messageService: MessageService, private af: AngularFirestore) {
    }

    ngOnInit(): void {
    }

    ngOnDestroy() {
    }

    partecipo(e: any): void {
        this.chkPartecipo = this.chkPartecipo === 1 ? -1 : 1;
        this.unselect(e);
    }

    nonPartecipo(e: any): void {
        this.chkPartecipo = this.chkPartecipo === 0 ? -1 : 0;
        this.unselect(e);
    }

    /**
     * Tolgo il focus all'elemento HTML se è stato deselezionato il tasto
     * @param e
     */
    unselect(e: any) {
        if (this.chkPartecipo === -1) {
            e.blur();
        }
    }

    toggleSuggerimento(): void {
        if (this.registrationForm.controls['nessunaPartecipazione'].value) {
            this.registrationForm.controls['suggerimento_nome'].disable();
            this.registrationForm.controls['suggerimento_cognome'].disable();
            this.registrationForm.controls['suggerimento_email'].disable();
        } else {
            this.registrationForm.controls['suggerimento_nome'].enable();
            this.registrationForm.controls['suggerimento_cognome'].enable();
            this.registrationForm.controls['suggerimento_email'].enable();
        }
    }

    /**
     * Valida una serie di field, almeno uno deve contenere il valore richiesto dalla variaible value
     *
     * @param fields Lista dei field in format array di stringhe [key1,key2...]
     * @param value valore da validare di tipo boolean|string|number
     * @return boolean restituisce true se almeno un valore contiene il valore indicato.
     */
    checkGroup(fields: string[], value: boolean | string | number) {
        for (const key of fields) {
            if (this.registrationForm.controls[key].value === value) {
                return true;
            }
        }
        return false;
    }

    submit(): void {
        this.messageService.clear();

        if (!this.registrationForm.controls['chk_privacy'].value) {
            this.messageService.add({severity: 'error', summary: 'Attenzione', detail: 'Accettare la privacy policy prima di continuare.', life: 3000, closable: false});
            return;
        }

        if (!this.registrationForm.controls['chk_cookie'].value) {
            this.messageService.add({severity: 'error', summary: 'Attenzione', detail: 'Accettare la cookie policy prima di continuare.', life: 3000, closable: false});
            return;
        }

        const body: any = {
            data: {
                sendConfirmation: true,
                event_id: this.event_id,
                uid: this.userData.uid,
                token_reg: this.token,
                userData: {
                    partecipa: false,
                    formCompletato: false,
                    custom_fields: {
                        intolleranze: '',
                        accompagnatore1_nome: '',
                        accompagnatore1_cognome: '',
                        accompagnatore1_email: '',
                        accompagnatore1_intolleranze: '',
                        accompagnatore2_nome: '',
                        accompagnatore2_cognome: '',
                        accompagnatore2_email: '',
                        accompagnatore2_intolleranze: '',
                        suggerimento_nome: '',
                        suggerimento_cognome: '',
                        suggerimento_email: '',
                        nessunaPartecipazione: false,
                        chkPrivacy: this.registrationForm.controls['chk_privacy'].value,
                        chkCookie: this.registrationForm.controls['chk_cookie'].value
                    }
                }
            }
        };

        if (this.chkPartecipo === 1) {
            if (this.registrationForm.invalid) {
                this.messageService.add({severity: 'error', summary: 'Attenzione', detail: 'Completare il form in ogni sua parte.', life: 2000, closable: false});
                return;
            }
            // console.log(1, this.registrationForm.controls['accompagnatore1_nome'].value.length);
            // console.log(2, this.registrationForm.controls['accompagnatore1_cognome'].value.length);
            // console.log(3, this.registrationForm.controls['accompagnatore1_email'].value.length);

            if (this.registrationForm.controls['accompagnatore1_email'].value.length > 0 || this.registrationForm.controls['accompagnatore1_nome'].value.length > 0 || this.registrationForm.controls['accompagnatore1_cognome'].value.length > 0) {
                if (this.registrationForm.controls['accompagnatore1_nome'].value.length === 0 || this.registrationForm.controls['accompagnatore1_cognome'].value.length === 0 || this.registrationForm.controls['accompagnatore1_email'].value.length === 0) {
                    console.warn('Completare i dati del primo accompagnatore.');
                    this.messageService.add({severity: 'error', summary: 'Attenzione', detail: 'Completare i dati del primo accompagnatore in ogni sua parte.', life: 2000, closable: false});
                    return;
                }
            }

            if (this.registrationForm.controls['accompagnatore2_email'].value.length > 0 || this.registrationForm.controls['accompagnatore2_nome'].value.length > 0 || this.registrationForm.controls['accompagnatore2_cognome'].value.length > 0) {
                if (this.registrationForm.controls['accompagnatore2_nome'].value.length === 0 || this.registrationForm.controls['accompagnatore2_cognome'].value.length === 0 || this.registrationForm.controls['accompagnatore2_email'].value.length === 0) {
                    console.warn('Completare i dati del secondo accompagnatore.');
                    this.messageService.add({severity: 'error', summary: 'Attenzione', detail: 'Completare i dati del secondo accompagnatore in ogni sua parte.', life: 2000, closable: false});
                    return;
                }
            }

            // Raccolgo i dati del form
            body.data.userData.partecipa = true;
            body.data.userData.formCompletato = true;
            body.data.userData.custom_fields.intolleranze = this.registrationForm.controls['intolleranze'].value;
            body.data.userData.custom_fields.accompagnatore1_nome = this.registrationForm.controls['accompagnatore1_nome'].value;
            body.data.userData.custom_fields.accompagnatore1_cognome = this.registrationForm.controls['accompagnatore1_cognome'].value;
            body.data.userData.custom_fields.accompagnatore1_email = this.registrationForm.controls['accompagnatore1_email'].value;
            body.data.userData.custom_fields.accompagnatore1_intolleranze = this.registrationForm.controls['accompagnatore1_intolleranze'].value;
            body.data.userData.custom_fields.accompagnatore2_nome = this.registrationForm.controls['accompagnatore2_nome'].value;
            body.data.userData.custom_fields.accompagnatore2_cognome = this.registrationForm.controls['accompagnatore2_cognome'].value;
            body.data.userData.custom_fields.accompagnatore2_email = this.registrationForm.controls['accompagnatore2_email'].value;
            body.data.userData.custom_fields.accompagnatore2_intolleranze = this.registrationForm.controls['accompagnatore2_intolleranze'].value;

            for (let i = 1; i < 8; i++) {
                for (let j = 1; j < 7; j++) {
                    const name = `postazione_${i}_${j}`;
                    const v = this.registrationForm.get(name)?.value;
                    const d = this.registrationForm.get(name)?.disabled;
                    if (d === false && v === true) {
                        body.data.userData.custom_fields[`postazione_${i}`] = `fascia_${j}`;
                    }
                }
            }
            // body.data.userData.custom_fields = {...body.data.userData.custom_fields, ...postazioni};

        } else if (this.chkPartecipo === 0) {
            // Controllo i dati della persona suggerita.
            if (!this.registrationForm.controls['nessunaPartecipazione'].value) {
                if (this.registrationForm.controls['suggerimento_nome'].value.length === 0 && this.registrationForm.controls['suggerimento_cognome'].value.length === 0 && this.registrationForm.controls['suggerimento_email'].value.length === 0) {
                    this.messageService.add({severity: 'error', summary: 'Attenzione', detail: 'Inserire i dati della persona che verrà al suo posto.', life: 2000, closable: false});
                    return;
                }

                if (this.registrationForm.controls['suggerimento_nome'].value.length > 0 || this.registrationForm.controls['suggerimento_cognome'].value.length > 0 || this.registrationForm.controls['suggerimento_email'].value.length > 0) {
                    if (this.registrationForm.controls['suggerimento_email'].invalid) {
                        this.messageService.add({severity: 'error', summary: 'Attenzione', detail: 'Controllare l\'eamil della persona suggerita.', life: 2000, closable: false});
                        return;
                    }

                    if (this.registrationForm.controls['suggerimento_nome'].value.length === 0 || this.registrationForm.controls['suggerimento_cognome'].value.length === 0 || this.registrationForm.controls['suggerimento_email'].value.length === 0) {
                        this.messageService.add({severity: 'error', summary: 'Attenzione', detail: 'Completare l\'inserimento dei dati della persona che verrà al suo posto.', life: 2000, closable: false});
                        return;
                    }
                }
            }

            // Raccolgo i dati del form
            body.data.sendConfirmation = false;
            body.data.userData.partecipa = false;
            body.data.userData.formCompletato = true;
            body.data.userData.custom_fields.nessunaPartecipazione = this.registrationForm.controls['nessunaPartecipazione'].value;
            if (!body.data.userData.custom_fields.nessunaPartecipazione) {
                body.data.userData.custom_fields.suggerimento_nome = this.registrationForm.controls['suggerimento_nome'].value;
                body.data.userData.custom_fields.suggerimento_cognome = this.registrationForm.controls['suggerimento_cognome'].value;
                body.data.userData.custom_fields.suggerimento_email = this.registrationForm.controls['suggerimento_email'].value;
            }
        } else {
            this.messageService.add({severity: 'error', summary: 'Attenzione', detail: 'Si prega di effettuare una scelta.', life: 2000, closable: false});
            return;
        }

        this.saving = true;

        // console.log(body);
        // this.saving = false;
        // return;

        this.api.userSignUp(body).then(res => {
            if (res.status === 200 && res.code === 'auth/ok') {
                this.messageService.add({severity: 'success', summary: 'Salvataggio', detail: 'Salvataggio dei dati completato!', life: 2000, closable: false});
                this.formSubmitted.emit({partecipa: body.data.userData.partecipa, formCompletato: body.data.userData.formCompletato});
            } else if (res.status === 200 && res.code === 'auth/already_completed') {
                this.messageService.add({severity: 'info', summary: 'Attenzione', detail: 'Dati già salvati.', life: 3000, closable: false});
            } else {
                this.messageService.add({severity: 'error', summary: 'Errore', detail: 'Errore durante il salvataggio, contatta la segreteria.', life: 3000, closable: false});
            }
        }).catch(err => {
            console.error('Errore durante il salvataggio', err);
            this.messageService.add({severity: 'error', summary: 'Errore', detail: 'Errore durante il salvataggio, contatta la segreteria.', life: 3000, closable: false});
        }).finally(() => {
            this.saving = false;
        });
    }
}
