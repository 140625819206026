import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {DefaultErrorComponent} from "../../core/default-error/default-error.component";
import {TimDemoComponent} from "./tim-demo.component";

const routes: Routes = [
    {path: '', pathMatch: 'full', component: TimDemoComponent, data: {guestLink: false}},
    // {path: 'request/unsubscribe/:event_id/:token_reg', pathMatch: 'full', component: TimDemoComponent, data: {unsubscribing: true}},
    {path: ':event_id/:group_id/:token_reg', pathMatch: 'full', component: TimDemoComponent, data: {guestLink: true}},
    {path: '**', component: DefaultErrorComponent}
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class TimDemoRoutingModule {
}
