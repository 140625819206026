<img class="img-fluid" src="assets/bonuomo-9-maggio-2024/header_content_creators.webp">

<div class="mt-5">
    <div class="text-3xl font-medium text-900 mb-3">Creatori e Diritti Digitali in Prospettiva di Riforma</div>
    <div class="font-medium text-600 mb-3">Un’Analisi Avanzata e Multidisciplinare sulle Dinamiche Legali che Incidono sul Settore dei Creatori di Contenuti Digitali
        In un’era caratterizzata da una rapidissima evoluzione tecnologica, i confini tra il diritto digitale e la creatività online stanno diventando sempre più sfumati. La necessità di un dialogo costruttivo tra professionisti legali, creatori di contenuti, e stakeholder del settore digitale si fa pressante, al fine di delineare un quadro normativo che possa favorire l’innovazione rispettando al contempo diritti e doveri di tutte le parti interessate.
    </div>
</div>
<div class="mt-5">
    <div class="text-3xl font-medium text-900 mb-3">Contesto e Finalità dell’Evento</div>
    <div class="font-medium text-600 mb-3">Il convegno mira a fornire una piattaforma di discussione avanzata sulle tematiche legali che riguardano i nuovi professionisti operanti nel mondo digitale. Attraverso l’esame di casi studio recenti, la disamina di controversie legali e l’analisi di prospettive di riforma, l’evento si propone di sensibilizzare e informare circa le dinamiche normative che influenzano il settore dei creatori digitali.</div>
</div>
<div class="mt-5">
    <div class="text-3xl font-medium text-900 mb-3">Tematiche Chiave</div>
    <div class="font-medium text-600 mb-3">Il convegno si articolerà in diverse sessioni tematiche, tra cui:
        <ul>
            <li>
                <strong>Il Balletto Normativo</strong>: Un’esplorazione del panorama legislativo attuale, inclusi il Regolamento Digital Chart, le linee guida AGCOM, e le implicazioni di leggi come il D. Lgs. 145/2007 e il Codice del Consumo per gli imprenditori digitali.
            </li>
            <li>
                <strong>Dal Web al Tribunale</strong>: Una riflessione sulle sfide legali poste da fenomeni come gli haters online, con un focus sul reato di diffamazione e le implicazioni della truffa digitale.
            </li>
            <li>
                <strong>Crazy Time: Cash, Fisco e Contributi</strong>: Un’analisi sull’inquadramento professionale degli influencer, le relazioni con l’Agenzia delle Entrate e il trattamento fiscale dei profitti globali.
            </li>
            <li>
                <strong>Avvocaty Influencer</strong>: Diritto e deontologia nell’era digitale, con particolare attenzione al bilanciamento tra opinione personale e professione legale nel contesto dei social media.
            </li>
            <li>
                <strong>Around the World in Digital Laws</strong>: Un confronto delle legislazioni europee e statunitensi relative agli influencer e ai content creators.
            </li>
        </ul>
    </div>
</div>
<div class="mt-5">
    <div class="text-3xl font-medium text-900 mb-3">Obiettivi dell’Evento</div>
    <div class="font-medium text-600 mb-3">L’evento si prefigge di:
        <ul>
            <li>
                Fornire una comprensione approfondita delle normative attuali e delle sfide legali specifiche del settore dei creatori di contenuti digitali.
            </li>
            <li>
                Stimolare un dibattito costruttivo tra professionisti legali, policy maker, e creatori di contenuti su come navigare le complessità del diritto digitale.
            </li>
            <li>
                Identificare vie di riforma legislative che possano equilibrare le esigenze di innovazione e creatività con la protezione dei diritti individuali e collettivi.
            </li>
        </ul>
    </div>
</div>
<div class="mt-5">
    <div class="text-3xl font-medium text-900 mb-3">Programma provvisorio della giornata</div>
    <div class="font-medium text-600 mb-3">
        <p><strong>Mariano Angelucci</strong> – Consigliere Città Metropolitana Roma e Consigliere Roma Capitale</p>
        <p><strong>Sara Zanotelli</strong> – Presidente AICDC</p>
        <p><strong>Andrea Bonuomo</strong> – Avvocato</p>
        <p><strong>Omar Castagnacci</strong> – Avvocato</p>
        <p><strong>Giampaolo Girardi</strong> – Avvocato</p>
        <p><strong>Carlo Borello</strong> – Avvocato</p>
        <p><strong>Pietro Di Tosto</strong> – Avvocato</p>
        <p><strong>Fabio Pompei e Alessandro Alongi</strong> – Giornalisti e autori del libro “Algoritmi, sicurezza ed etica dell’innovazione”</p>
        <p><strong>Massimiliano Capitanio</strong> – Commissario AGCOM</p>
        <p><strong>Giordano Cutarelli</strong>Comandante Nucleo Speciale beni e servizi Guardia di Finanza</p>
        <p><strong>M. D’Onofrio</strong> - Vice Presidente AICDC</p>
        <p><strong>Sen. Giorgio Maria Bergesio</strong> - Lega</p>
        <p><strong>M. Dona</strong> - Presidente Unione Nazionale Consumatori</p>
        <p><strong>On. Raffaella Paita</strong> - Italia Viva</p>
        <p><strong>C. Casolo</strong> - Consulente AICDC</p>
        <p><strong>C. Durigon</strong> - Sottosegretario Ministero Lavoro e Politiche Sociali</p>
        <p><strong>S. Lepri</strong> - Creator</p>
        <p><strong>A. Pone</strong> - Direttore Centrale Entrate INPS</p>
        <p><strong>E. Ruffini</strong> - Direttore Agenzia delle Entrate</p>
        <p><strong>F. Bassa</strong> - Avvocato</p>
        <p><strong>F. Florio</strong> - Creator</p>
        <p><strong>A. Greco</strong> - Creator</p>
        <p><strong>R. Lanzo</strong> - IUS40</p>
        <p><strong>D. Ciulli</strong> - Head of public policy Google</p>
        <p><strong>A. Barachini</strong> - Sottosegretario Editoria</p>
        <p><strong>A. Butti</strong> - Sottosegretario Innovazione Tecnologica</p>
        <p><strong>A. Mazzi</strong> - Sottosegretario Ministero Cultura</p>
        <p><strong>A. Tenerini</strong> - FI</p>
        <p><strong>A. Valente</strong> - Vice Presidente AICDC</p>
    </div>
</div>
