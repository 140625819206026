import pkg from '../../package.json';

export const environment = {
    appVersion: pkg.version + '-dev',
    production: false,
    EVENT_ID: 'IlWfqyB14T8OXMUOtkyL-open-dev',
    URL_API: 'https://europe-west3-gevents-dev.cloudfunctions.net',
    // URL_API: 'http://localhost:5001/gevents-dev/europe-west3',
    backet: 'gevents_dev',
    event_id: 'IlWfqyB14T8OXMUOtkyL-open-dev',
    firebaseConfig: {
        apiKey: 'AIzaSyBEabOdnXkU6wgbQyJ3hDbff9RyEC_aqDM',
        authDomain: 'gevents-dev.firebaseapp.com',
        projectId: 'gevents-dev',
        storageBucket: 'gevents-dev.appspot.com',
        messagingSenderId: '108633849925',
        appId: '1:108633849925:web:1771eed1ee1d9bf9d3632c',
        measurementId: 'G-2R31LVG65T'
    }
};
