import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-sito',
    templateUrl: './sito.component.html',
    styleUrls: ['./sito.component.scss']
})
export class SitoComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {
    }

    openAccordion(arrow: any, el: any) {
        if (el.style.maxHeight) {
            el.style.maxHeight = null;
            arrow.classList.add('arrowLeft');
        } else {
            el.style.maxHeight = el.scrollHeight + "px";
            arrow.classList.remove('arrowLeft');
        }
    }
}
