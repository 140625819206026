import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PrimengModule} from '../modules/primeng.module';
import {ReactiveFormsModule} from '@angular/forms';
import {DefaultErrorComponent} from "./default-error/default-error.component";

@NgModule({
    declarations: [
        DefaultErrorComponent
    ],
    imports: [
        CommonModule,
        PrimengModule,
        ReactiveFormsModule
    ],
    exports: [
        DefaultErrorComponent
    ]
})
export class CoreModule {
}
