import {AfterViewInit, Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {firstValueFrom} from 'rxjs';

// Funzione esterna per il cambio del loadingText
declare var disableLoading: Function;

@Component({
    selector: 'app-bonuomo9-maggio2024',
    templateUrl: './bonuomo9-maggio2024.component.html',
    styleUrls: ['./bonuomo9-maggio2024.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class Bonuomo9Maggio2024Component implements OnInit, AfterViewInit {

    eventId = '';

    constructor(private route: ActivatedRoute) {
    }

    ngOnInit() {
        firstValueFrom(this.route.params).then(x => {
            // console.log(x.event_id);
            this.eventId = x.event_id;
        })
    }

    ngAfterViewInit() {

        // Richiamo la funzione per disattivare il loading
        disableLoading();
    }
}
