import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {LangService} from "./lang.service";
import {ILanguage} from "../languages/ILanguage";

@Injectable()
export class AddLangInterceptor implements HttpInterceptor {
  constructor(private langSrv: LangService<ILanguage>) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Clona la richiesta per aggiungere il nuovo parametro
    if (req.url.includes('/fw2023/') && !req.body.data.hasOwnProperty('lang')) {
      console.log('Adding language to http post.');
      const clonedRequest = req.clone({
        body: {
          ...req.body,
          data: {...req.body.data, lang: this.langSrv.getCurrentLang()}
        }
      });
      // console.log(req.body.data);
      // console.log(clonedRequest.body.data);

      // Passa la nuova richiesta clonata invece dell'originale
      return next.handle(clonedRequest);
    } else {
      return next.handle(req);
    }
  }
}
