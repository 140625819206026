import {AfterViewInit, Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';

// Funzione esterna per il cambio del loadingText
declare var disableLoading: Function;

@Component({
    selector: 'app-default-error',
    templateUrl: './default-error.component.html',
    styleUrls: ['./default-error.component.scss']
})
export class DefaultErrorComponent implements OnInit, AfterViewInit {

    constructor(private titleService: Title) {
        titleService.setTitle('Error page');
    }

    ngOnInit(): void {
    }

    ngAfterViewInit() {
        disableLoading();
    }
}
