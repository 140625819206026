import {AfterViewInit, Component, OnInit} from '@angular/core';
import eventi from './db/eventi.json';
import {Title} from '@angular/platform-browser';
import {environment} from '../environments/environment';
import {PrimeNGConfig} from 'primeng/api';

// Funzione esterna per il cambio del loadingText
declare var changeLoadingText: Function;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
    constructor(private titleService: Title, private primengConfig: PrimeNGConfig) {
        titleService.setTitle('Event Management');
        console.log('Landing v' + environment.appVersion);
    }

    async ngOnInit() {
        // await this.langSrv.checkUrlLang();

        this.primengConfig.ripple = true;
        const url = window.location.origin + '/' + document.location.pathname.split('/')[1];

        const event = eventi.find(x => {
            if (url.includes(x.dominio)) {
                return x;
            }
            return null;
        });

        if (event) {
            const favIcon: HTMLLinkElement = document.querySelector("link[rel*='icon']")!;
            favIcon.href = event.favicon;
            this.titleService.setTitle(event.title);
        }
    }

    ngAfterViewInit() {
        changeLoadingText('... data is loaded, checking event ...');
    }
}
