<div class="surface-card shadow-2 border-round colonna-centrale p-2 md:p-0 pb-8">
    <!--<div class="flex flex-column align-items-center justify-content-start min-h-screen colonna-centrale">-->
    <div class="flex justify-content-between align-items-center flex-row header">
        <img alt="ditosto-logo" class="logo" height="146" src="assets/bonuomo-9-maggio-2024/ditosto.jpg">
        <div *ngIf="eventId && eventId !== ''" class="titolo-convegno">
            Creators e diritti digitali
        </div>
        <img alt="bonuomo-logo" class="logo" height="146" src="assets/bonuomo-9-maggio-2024/bonuomo.jpg">
    </div>

    <div class="mb-5 mt-5 px-0 sm:px-6">
        <app-sito></app-sito>
    </div>

    <ng-container *ngIf="eventId && eventId !== ''; else noEvent">
        <div class="mb-5 mt-5 px-0 sm:px-6">
            <a id="form" name="form"></a>
            <app-form [event_id]="eventId"></app-form>
        </div>
    </ng-container>
    <ng-template #noEvent>
        <hr class="divisorio" style="width: 90%">
        <div class="mb-5 mt-2 event-info px-0 sm:px-6 inline-block">
            <h4>Errore: evento non trovato.</h4>
            <p>Verifica il link o scrivi all'indirizzo <a href="mailto:andreabonuomo@studioditostobonuomo.it">andreabonuomo@studioditostobonuomo.it</a></p>
        </div>
    </ng-template>
    <div class="powered-by-giacapp">Powered by<br><a href="https://giacapp.com" target="giacapp"><img src="assets/logoGiacApp_small.png" width="150" class="vertical-align-middle my-2"></a></div>
</div>

<!-- TOAST -->
<p-toast [breakpoints]="{'920px': {width: '100%', right: '0', left: '0'}}"></p-toast>
