import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {DatePipe, registerLocaleData} from '@angular/common';
import {AngularFireModule} from '@angular/fire/compat';
import {environment} from '../environments/environment';
import {AngularFireStorageModule, BUCKET} from '@angular/fire/compat/storage';
import '../prototypes/implementations';
import localeIt from '@angular/common/locales/it';
import {AddLangInterceptor} from "./services/addLangInterceptor";
import {TooltipModule} from "primeng/tooltip";
import {TimDemoModule} from './landings/tim-demo/tim-demo.module';
import {ProgressSpinnerModule} from "primeng/progressspinner";
import {ButtonModule} from "primeng/button";
import {CheckboxModule} from "primeng/checkbox";
import {Bonuomo9Maggio2024Module} from "./landings/bonuomo-9-maggio-2024/bonuomo9-maggio2024.module";

registerLocaleData(localeIt, 'it');

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        TooltipModule,
        HttpClientModule,
        AngularFireModule.initializeApp(environment.firebaseConfig),
        AngularFireStorageModule,
        TimDemoModule,
        Bonuomo9Maggio2024Module,
        ProgressSpinnerModule,
        ButtonModule,
        CheckboxModule
    ],
    providers: [
        DatePipe,
        {provide: HTTP_INTERCEPTORS, useClass: AddLangInterceptor, multi: true},
        {provide: BUCKET, useValue: environment.backet}
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
