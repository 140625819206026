<ng-container *ngIf="loading; else loaded">
    <div class="flex h-screen justify-content-center align-items-center">
        <p-progressSpinner class=""></p-progressSpinner>
    </div>
</ng-container>

<ng-template #loaded>
    <div class="flex flex-column align-items-center justify-content-start min-h-screen">
        <!-- HEADER -->
        <div class="text-center m-0 p-0 w-full">
            <img class="img-fluid tim-header" alt="biomedica-header">
        </div>

        <!-- CONTAINER PRINCIPALE -->
        <ng-container *ngIf="!unsubscribing; else unsubscribe">
            <ng-container *ngIf="tokenValid; else tokenNotValidTemplate">
                <ng-container *ngIf="!alreadyVerified; else alreadyVerifiedTemplate">
                    <app-form-tim-demo (formSubmitted)="formSubmitted($event)" [event_id]="event_id" [token]="token_reg" [userData]="userData"></app-form-tim-demo>
                </ng-container>

                <ng-template #alreadyVerifiedTemplate>
                    <div class="col-12 text-center" style="margin-top: 50px; margin-bottom: 50px;">
                        <span>Grazie per il feedback!</span><br><br>
                        <span>Il Team di <span class="color-tim-blue">TIM Demo</span></span>
                    </div>
                </ng-template>
            </ng-container>

            <ng-template #tokenNotValidTemplate>
                <ng-container *ngIf="guestLink">
                    <div class="col-12 text-center">
                        <h2 class="text-red-500">Dati non validi</h2>
                    </div>
                </ng-container>
            </ng-template>
        </ng-container>

        <ng-template #unsubscribe>
            <ng-container *ngIf="tokenValid; else statusErrorTemplate">
                <div class="grid justify-content-center text-center">
                    <div class="col-12 mt-6 text-base">
                        Indirizzo email: <span class="font-italic font-bold">{{ userData.email }}</span>
                    </div>
                    <div class="col-12 w-100 mt-1">
                        <div class="p-field-radiobutton">
                            <p-checkbox #checkbox [(ngModel)]="unsubscribeCheck" [binary]="true" class="" id="unsubscribe" name="unsubscribe"></p-checkbox>
                            <label class="ml-1" for="unsubscribe">{{ checkbox.checked() ? 'Voglio continuare a ricevere email.' : 'Non voglio più ricevere email.' }}</label>
                        </div>
                    </div>
                    <div class="mt-4 mb-6 col-12">
                        <button (click)="unsubscribeSubmit()" [disabled]="saving" [loading]="saving" class="button-blue" label="SALVA LA PREFERENZA" pButton type="button"></button>
                    </div>
                </div>
            </ng-container>

            <ng-template #statusErrorTemplate>
                <div class="mt-6 mb-6 col-12 text-center">
                    <h2 class="text-red-500">Dati non validi</h2>
                </div>
            </ng-template>
        </ng-template>


        <!-- FOOTER -->
        <div class="tim-footer align-self-center flex flex-column align-items-center justify-content-center mt-5 color-tim-blue">
            <p><a style="color:var(--tim-blue) !important;" href="mailto:segreteria@dev.gevents.it">segreteria@dev.gevents.it</a> | +39 392 7951868</p>
        </div>
    </div>
</ng-template>

<!-- TOAST -->
<p-toast [breakpoints]="{'920px': {width: '100%', right: '0', left: '0'}}"></p-toast>
