<div class="surface-card shadow-2 border-round p-4 mb-2 inline-block">
    <div>
        <div class="text-3xl font-medium text-900 mb-3">Registrazione</div>
        <div class="font-medium text-600 mb-3">
            <p>L’evento è rivolto ad accademici, professionisti del diritto, influencer, policy maker.</p>
            <p class="font-bold">Data: 9 maggio 2024 dalle 15 alle 18</p>
            <p class="font-bold">Luogo: Aula consiliare Giorgio Fregosi – Palazzo Valentini (Via Quattro Novembre, 119/a)</p>
        </div>
    </div>

    <ng-container *ngIf="formCompleted > 0; else showForm">
        <div class="mb-5 mt-5 event-info px-2 sm:px-4 text-center">
            <h4>Registrazione <span *ngIf="formCompleted === 2">già</span> completata.</h4>
            <p class="font-medium text-600">Grazie per esserti iscritto all'evento!</p>
        </div>
    </ng-container>

    <ng-template #showForm>
        <form [formGroup]="registrationForm" class="mt-4">
            <div class="grid">
                <div class="grid justify-content-center align-items-center sm:m-auto" style="width: 100%;">
                    <div class="col-12 md:col-6 mb-4">
                    <span class="p-float-label">
                        <input formControlName="nome" id="nome" name="nome" pInputText/>
                        <label for="nome">Nome</label>
                    </span>
                    </div>
                    <div class="col-12 md:col-6 mb-4">
                    <span class="p-float-label">
                      <input formControlName="cognome" id="cognome" name="cognome" pInputText/>
                      <label for="cognome">Cognome</label>
                    </span>
                    </div>
                    <div class="col-12 md:col-6 mb-4">
                    <span class="p-float-label">
                        <input formControlName="email" id="email" name="email" pInputText/>
                        <label for="email" type="email">E-mail</label>
                    </span>
                    </div>
                    <div class="col-12 md:col-6 mb-4">
                    <span class="p-float-label">
                        <input formControlName="cellulare" id="cellulare" name="cellulare" pInputText/>
                        <label for="cellulare">Numero di cellulare</label>
                    </span>
                    </div>
                </div>

                <hr class="divisorio">

                <div class="grid justify-content-center align-items-center sm:m-auto" style="width: 100%;">
                    <div class="col-12">
                        <p class="font-medium text-600 text-justify">Ai sensi del Regolamento Europeo per la protezione dei dati UE/2016/679, i dati personali sono raccolti con modalità telematiche e trattati con modalità automatizzate, unicamente per le finalità connesse all'evento. Il conferimento di tali dati da parte del richiedente ed il consenso al loro trattamento sono facoltativi; in difetto, le richieste non potranno essere evase. I dati personali non saranno in alcun modo divulgati. L'interessato potrà esercitare i diritti previsti dagli artt. da 15 a 22 dal Regolamento Europeo per la protezione dei dati UE/2016/679, facendone espressa richiesta alla seguente email: <a href="mailto:andreabonuomo@studioditostobonuomo.it">andreabonuomo@studioditostobonuomo.it</a>. Autorizzo lo Studio Legale Di Tosto Bonuomo al trattamento dei miei dati personali per finalità connesse all'evasione della richiesta.</p>
                    </div>
                    <div class="col-12">
                        <div class="field-checkbox flex align-items-center justify-content-start font-medium text-600">
                            <p-checkbox [binary]="true" class="mr-3 cursor-pointer" formControlName="chk_privacy" inputId="chk_privacy"></p-checkbox>
                            <label [for]="'chk_privacy'" class="cursor-pointer privacy">Accetto la privacy policy</label>
                        </div>
                    </div>

                    <div class="col-12 mt-4">
                        <p-button (click)="submit()" [disabled]="saving" [loading]="saving" class="m-2 single-button flex-grow-1 flex align-items-center justify-content-center" label="INVIA"></p-button>
                    </div>
                </div>
            </div>
        </form>
    </ng-template>
</div>
