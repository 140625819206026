import {Injectable} from '@angular/core';
import {ELang} from '../interfaces';

@Injectable(
  {providedIn: 'root'}
)
export class LangService<T> {
  private currentLang: ELang | undefined;
  private langDb: {} | null = null;
  private eng: {} | null = null;
  private ita: {} | null = null;

  constructor() {
  }

  /**
   * Inizializza il servizio delle lingue
   *
   * @param defaultLang {Object|'auto'} Lingua di default
   * @param ita {Object} Lingua italiana
   * @param eng {Object} Lingua english
   */
  public init(defaultLang: ELang | 'auto', ita: T, eng: T) {
    if (eng) this.eng = eng as T;
    if (ita) this.ita = ita as T;

    if (defaultLang === 'auto') {
      defaultLang = this.getBrowserLanguage();
    }

    this.changeLang(defaultLang);
    this.checkUrlLang();
  }

  getBrowserLanguage() {
    let browserLang = navigator.language || 'en'; // Ottieni la lingua preferita dal browser
    if (browserLang.length > 2) {
      browserLang = browserLang.substring(0, 2);
    }
    console.log(`Browser language: ${browserLang}`);
    switch (browserLang) {
      case 'it':
        return ELang.italiano;
      case 'en':
        return ELang.english;
      default:
        console.log(`Browser language ${browserLang} is not supported yet, using english as default language.`);
        return ELang.english;
    }
  }

  /**
   * Restituisce il database della lingua attualmente caricata
   *
   */
  public getLangDb(): T {
    if (this.langDb) return this.langDb as T;
    console.error('Lingua non definita');
    return {} as T;
  }

  /**
   * Restituisce la lingua attuale
   */
  public getCurrentLang(): string {
    return this.currentLang || '';
  }

  /**
   * Controlla se una lingua è stata caricata
   */
  public async checkUrlLang() {
    const url = window.location.href.toLowerCase();
    const langIdx = url.indexOf('lang=');

    if (langIdx === -1) {
      console.log('No url language override');
      return;
    }

    const lang = url.substring(langIdx + 5, langIdx + 5 + 2);
    this.changeLang(lang);
  }

  /**
   * Cambia la lingua corrente senza ricaricare
   * @param newLang
   */
  public changeLang(newLang: string) {
    if (newLang === this.currentLang) {
      return;
    }

    switch (newLang) {
      case 'it':
        this.langDb = this.ita;
        this.currentLang = ELang.italiano;
        break;
      case 'us':
      case 'en':
        this.langDb = this.eng;
        this.currentLang = ELang.english;
        break;
      default:
        console.log(`Language ${newLang} is not supported yet.`);
      // this.langDb = this.eng;
      // this.currentLang = ELang.english;
    }
  }
}
